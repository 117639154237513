import Vue from 'vue'
import VueRouter from 'vue-router'
import API from '../services/api'
const Auth = () => import('../views/Auth.vue')
const SignIn = () => import('../views/SignIn.vue')
const PasswordReset = () => import('../views/PasswordReset.vue')
const Content = () => import('../views/Content.vue')
const Quote = () => import('../views/Quotes/Quote.vue')
const QuoteGeneralInfo = () => import('../views/Quotes/QuoteGeneralInfo.vue')
const QuoteCommercialTerms = () =>
    import('../views/Quotes/QuoteCommercialTerms.vue')
const QuoteCosts = () => import('../views/Quotes/QuoteCosts.vue')
const QuoteChangelog = () => import('../views/Quotes/QuoteChangelog.vue')
const Clients = () => import('../views/Clients/Clients.vue')
const NoClient = () => import('../views/Clients/NoClient.vue')
const Users = () => import('../views/Users/Users.vue')
const UsersLogs = () => import('../views/Users/UsersLogs.vue')
const Client = () => import('../views/Clients/Client.vue')
const NewClient = () => import('../views/Clients/NewClient.vue')
const ClientGeneralInfo = () => import('../views/Clients/ClientGeneralInfo.vue')
const ClientProjects = () => import('../views/Clients/ClientProjects.vue')
const ClientDocuments = () => import('../views/Clients/ClientDocuments.vue')
const UsersView = () => import('../views/Users/UsersView.vue')
const UsersPermissions = () => import('../views/Users/UsersPermissions.vue')
const NoUser = () => import('../views/Users/NoUser.vue')
const User = () => import('../views/Users/User.vue')
const NewUser = () => import('../views/Users/NewUser.vue')
const UserGeneralInfo = () => import('../views/Users/UserGeneralInfo.vue')
const UserDocuments = () => import('../views/Users/UserDocuments.vue')
const UserPermissions = () => import('../views/Users/UserPermissions.vue')
const MainBoard = () => import('../views/MainBoard/MainBoard.vue')
const Estimating = () => import('../views/Estimating/Estimating.vue')
const CostAssing = () => import('../components/Engineering/CostAssign.vue')
const Engineering = () => import('../components/Engineering/Engineering.vue')
const EngineeringHistory = () =>
    import('../components/Engineering/EngineeringHistory.vue')
const History = () => import('../views/History/History.vue')
const NoHistory = () => import('../views/History/NoHistory.vue')
const PurchasingQuotes = () =>
    import('../components/Purchasing/PurchasingQuotes.vue')
// const HistoryCommercialTerms = () =>
//   import("../views/History/HistoryCommercialTerms.vue");
// const HistoryCosts = () => import("../views/History/HistoryCosts.vue");
// const HistoryVersions = () => import("../views/History/HistoryVersions.vue");
// const HistoryGeneralInfo = () =>
//   import("../views/History/HistoryGeneralInfo.vue");
const QuoteHistory = () => import('../views/History/QuoteHistory.vue')
const Tools = () => import('../views/Tools/Tools.vue')
const NoTool = () => import('../views/Tools/NoTool.vue')
const ImagesTools = () => import('../views/Tools/ImagesTools.vue')
const CommercialImages = () => import('../views/Tools/CommercialImages.vue')
const DocumentTools = () => import('../views/Tools/DocumentTools.vue')
const TechnicalDocuments = () => import('../views/Tools/TechnicalDocuments.vue')
const CommercialDocuments = () =>
    import('../views/Tools/CommercialDocuments.vue')
const Settings = () => import('../views/Settings/Settings.vue')
const SettingsCosts = () => import('../views/Settings/SettingsCosts.vue')
const SettingsCurrencies = () =>
    import('../views/Settings/SettingsCurrencies.vue')
const SettingsImage = () => import('../views/Settings/SettingsImage.vue')
const SettingsCommercial = () =>
    import('../views/Settings/SettingsCommercial.vue')
const QuoteRangesSettings = () =>
    import('../views/Settings/QuoteRangesSettings.vue')
const SettingsGeneralQuote = () =>
    import('../views/Settings/SettingsGeneralQuote.vue')
const QuoteSettings = () => import('../views/Settings/QuoteSettings.vue')
const RolesSettings = () => import('../views/Settings/RolesSettings.vue')
const SettingsUnits = () => import('../views/Settings/SettingsUnits.vue')
const ExchangeSettings = () => import('../views/Settings/ExchangeSettings.vue')
const CompanySettings = () => import('../views/Settings/CompanySettings.vue')
const CalculationSettings = () =>
    import('../views/Settings/CalculationSettings.vue')
const CompanyGeneralInfo = () =>
    import('../views/Settings/CompanyGeneralInfo.vue')
const RolesPermissions = () => import('../views/Settings/RolesPermissions.vue')
const RolesPermissionsDetails = () =>
    import('../views/Settings/RolesPermissionsDetails.vue')
const NoItem = () => import('../views/Settings/NoItem.vue')
import { auth } from '../services/firebase'
import store from '../store'
import PublicWorkOrder from '../components/WorkOrders/PublicWorkOrder.vue'
import QuoteMilestones from '../components/Milestones/QuoteMilestones.vue'
import TimesheetReports from '../components/Timesheets/TimesheetReports.vue'
import TimesheetSettings from '../components/Timesheets/TimesheetSettings.vue'
import DailyReports from '../components/Timesheets/DailyReports.vue'
import WeeklyReports from '../components/Timesheets/WeeklyReports.vue'
import Deliveries from '../components/WorkOrders/Deliveries.vue'
import Progress from '../components/WorkOrders/Progress.vue'
import ProjectSettings from '../views/Settings/ProjectSettings.vue'
import ProjectReports from '../components/Settings/ProjectReports.vue'
import ForecastReports from '../components/Settings/ForecastReports.vue'
const LeadNotifications = () =>
    import('../components/Settings/Notifications/Leads/LeadNotifications.vue')
const POC = () => import('../components/Projects/POC.vue')
const Chats = () => import('../views/Chat/Chats.vue')
const ProjectChats = () => import('../views/Chat/ProjectChats.vue')
const NoChat = () => import('../views/Chat/NoChat.vue')
const LaunchScreen = () => import('../views/LaunchScreen')
const QuotesByStatus = () => import('../views/MainBoard/QuotesByStatus')
const QuotesByAges = () => import('../views/MainBoard/QuotesByAges.vue')
const Statistics = () => import('../views/Statistics/Statistics.vue')
const QuoteFollowUp = () => import('../views/Quotes/QuoteFollowUp.vue')
const CompanyTags = () => import('../views/Settings/CompanyTags.vue')
const Calculations = () => import('../views/Calculations/Calculations.vue')
const Calculation = () => import('../views/Calculations/Calculation.vue')
const CalculationInputs = () =>
    import('../views/Calculations/CalculationInputs.vue')
const CalculationParams = () =>
    import('../views/Calculations/CalculationParams.vue')
const ProjectTabs = () => import('../views/Projects/ProjectTabs.vue')
const GenericProjects = () =>
    import('../components/Projects/GenericProjects.vue')
const Projects = () => import('../views/Projects/Projects.vue')
const Nomina = () => import('../components/Projects/Nomina.vue')
const Project = () => import('../views/Projects/Project.vue')
const ArchiveProjects = () => import('../views/Projects/ArchiveProjects.vue')
const ProjectDetails = () => import('../views/Projects/ProjectDetails.vue')
const ProjectQuotes = () => import('../views/Projects/ProjectQuotes.vue')
const Forecast = () => import('../components/Forecast/Forecast.vue')
const TasksBoard = () => import('../views/Tasks/Board.vue')
const AssignBoard = () => import('../views/Tasks/Assign.vue')
const ArchivedTasks = () => import('../views/Tasks/ArchivedTasks.vue')
const StockRoom = () => import('../views/Projects/StockRoom.vue')
const Purchasing = () => import('../views/Projects/Purchasing.vue')
const Comex = () => import('../views/Projects/Comex.vue')
const ComexFinished = () => import('../components/Comex/ComexFinished.vue')
const ComexMainBoard = () => import('../components/Comex/ComexMainBoard.vue')
const PurchasingRequest = () =>
    import('../components/Purchasing/PurchasingRequest.vue')
const PurchasingPricing = () =>
    import('../components/Purchasing/PurchasingPricing.vue')
const PurchasingApproval = () =>
    import('../components/Purchasing/PurchasingApproval.vue')
const PurchasingProcess = () =>
    import('../components/Purchasing/PurchasingProcess.vue')
const PurchasingInTransit = () =>
    import('../components/Purchasing/PurchasingInTransit.vue')
const PurchasingClosed = () =>
    import('../components/Purchasing/PurchasingClosed.vue')
const PurchasingRejectedLog = () =>
    import('../components/Purchasing/PurchasingRejectedLog.vue')
const PurchasingAssign = () =>
    import('../components/Purchasing/PurchasingAssign.vue')
const PurchasingStatus = () =>
    import('../components/Purchasing/PurchasingStatus.vue')
const Suppliers = () => import('../views/Projects/Suppliers.vue')
const Leading = () => import('../views/Leads/Leading.vue')
const Leads = () => import('../components/Leads/Leads.vue')
const NoSupplier = () => import('../components/Suppliers/NoSupplier.vue')
const Supplier = () => import('../components/Suppliers/Supplier.vue')
const SupplierGeneralInfo = () =>
    import('../components/Suppliers/SupplierGeneralInfo.vue')
const PurchasingPreApproval = () =>
    import('../components/Purchasing/PurchasingPreApproval.vue')
const PurchasingReceived = () =>
    import('../components/Purchasing/PurchasingReceived.vue')
const Stock = () => import('../components/StockRoom/Stock.vue')
const Request = () => import('../components/StockRoom/Request.vue')
const Reserves = () => import('../components/StockRoom/Reserves.vue')
const PartNumbers = () => import('../components/StockRoom/PartNumbers.vue')
const TemporaryOffline = () =>
    import('../views/TemporaryOffline/TemporaryOffline.vue')
const WorkOrders = () => import('../views/Projects/WorkOrders.vue')
const MainWorkOrders = () =>
    import('../components/WorkOrders/MainWorkOrders.vue')
const ApprovalWO = () => import('../components/WorkOrders/ApprovalWO.vue')
const ApprovedWO = () => import('../components/WorkOrders/ApprovedWO.vue')
const PendingQA = () => import('../components/WorkOrders/PendingQA.vue')
const RejectedQA = () => import('../components/WorkOrders/RejectedQA.vue')
const ApprovalBOM = () => import('../components/WorkOrders/ApprovalBOM.vue')
const ApprovedBOM = () => import('../components/WorkOrders/ApprovedBOM.vue')
const Closed = () => import('../components/WorkOrders/Closed.vue')
const WorkOrderSettings = () =>
    import('../views/Settings/WorkOrderSettings.vue')
const Processes = () => import('../components/Processes/Processes.vue')
const Products = () => import('../components/Products/Products.vue')
const QualitySettings = () => import('../views/Settings/QualitySettings.vue')
const NCcodes = () => import('../components/Quality/NCcodes.vue')
const Operators = () => import('../components/Operators/Operators.vue')
const ProjectInvoices = () => import('../views/Projects/ProjectInvoices.vue')
const B2BForecast = () => import('../components/Forecast/B2BForecast.vue')
const ProjectPackingLists = () =>
    import('../views/Projects/ProjectPackingLists.vue')
const Machines = () => import('../components/Machines/Machines.vue')
const SpecialMaterials = () =>
    import('../components/SpecialMaterials/SpecialMaterials.vue')
const InvoiceSettings = () => import('../views/Settings/InvoiceSettings.vue')
const InvoiceConcepts = () => import('../views/Settings/InvoiceConcepts.vue')
const InvoiceCategories = () =>
    import('../views/Settings/InvoiceCategories.vue')
const InvoiceTypes = () => import('../views/Settings/InvoiceTypes.vue')
const FinishAndUCCode = () =>
    import('../components/FinishAndUCCode/FinishAndUCCode.vue')
const PurchasesStatistics = () =>
    import('../components/Purchasing/PurchasesStatistics.vue')
const PurchasesIndicators = () =>
    import('../components/Purchasing/Indicators.vue')
const QuoteLogs = () => import('../views/Quotes/QuoteLogs.vue')
const ProjectLogs = () => import('../views/Projects/ProjectLogs.vue')
const Invoicing = () => import('../views/Projects/Invoicing.vue')
const Invoices = () => import('../components/Invoicing/Invoices.vue')
const International = () => import('../components/Invoicing/International.vue')
const Generating = () => import('../components/Invoicing/Generating.vue')
const ClosedInvoices = () => import('../components/Invoicing/Closed.vue')
const ProjectReserves = () =>
    import('../components/Projects/ProjectReserves.vue')
const ProjectTimesheets = () =>
    import('../components/Projects/ProjectTimesheets.vue')
const ProjectRequested = () =>
    import('../components/Projects/ProjectRequested.vue')
const WOSearch = () => import('../components/WorkOrders/WOSearch.vue')
const BOMSearch = () => import('../components/WorkOrders/BOMSearch.vue')
const OperatorView = () => import('../views/Operator/Operator.vue')
const OperatorWOs = () => import('../components/WorkOrders/OperatorWOs.vue')
const OperatorClosedWOs = () =>
    import('../components/WorkOrders/OperatorClosedWOs.vue')
//const OperatorBOMs = () => import('../components/WorkOrders/OperatorBOMs.vue')
const Canceled = () => import('../components/WorkOrders/Canceled.vue')
const PartialDeliveryRequests = () =>
    import('../components/WorkOrders/PartialDeliveryRequests.vue')
const OperatorSearch = () =>
    import('../components/WorkOrders/OperatorSearch.vue')
const BOMsDelivered = () => import('../components/WorkOrders/BOMsDelivered.vue')

const Packing = () => import('../views/Projects/Packing.vue')
const ReadyForPackingWO = () =>
    import('../components/Packing/ReadyForPackingWO.vue')
const MainPacking = () => import('../components/Packing/MainPacking.vue')
const PendingPacking = () => import('../components/Packing/PendingPacking.vue')
const ReferredPacking = () =>
    import('../components/Packing/ReferredPacking.vue')
const ScheduledPacking = () =>
    import('../components/Packing/ScheduledPacking.vue')
const ShippedPacking = () => import('../components/Packing/ShippedPacking.vue')
const CompletedPacking = () =>
    import('../components/Packing/CompletedPacking.vue')
const PackingBalance = () => import('../components/Packing/PackingBalance.vue')
const ClosingProjects = () => import('../views/Projects/ClosingProjects.vue')
const Milestones = () => import('../components/Milestones/Milestones.vue')
const Timesheets = () => import('../components/Timesheets/Timesheets.vue')
const CreateReport = () => import('../components/Timesheets/CreateReport.vue')
const WeeklyTimeKeepingReports = () =>
    import('../components/Timesheets/WeeklyTimeKeepingReports.vue')
const NotificationSettings = () =>
    import('../views/Settings/NotificationSettings.vue')
const LeadTemplate = () =>
    import('../components/Settings/Notifications/Leads/LeadTemplate.vue')
const MyScopes = () => import('../components/Scopes/MyScopes.vue')
const NoScopes = () => import('../components/Scopes/NoScopes.vue')
const NewScopeForm = () => import('../components/Scopes/NewScopeForm.vue')
import OperatorsFinishedWOs from '../components/WorkOrders/OperatorsFinishedWorkOrders.vue'
const Finished = () => import('../components/WorkOrders/Finished.vue')

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: Auth,
        children: [
            {
                path: 'sign-in',
                name: 'sign in',
                component: SignIn,
            },
            {
                path: 'reset-password',
                name: 'reset password',
                component: PasswordReset,
            },
        ],
    },
    {
        path: '/purchase-quotes',
        name: 'purchase quotes',
        component: PurchasingQuotes,
        meta: {
            requiresAuth: false,
        },
        props: true,
    },
    {
        path: '/timesheets',
        name: 'timesheets',
        component: Timesheets,
        meta: {
            requiresAuth: false,
        },
        props: true,
    },
    {
        path: '/',
        name: '',
        component: Content,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'tasks',
                name: 'tasks',
                component: TasksBoard,
                meta: {
                    requiresAuth: true,
                },
                props: true,
            },
            {
                path: 'assign',
                component: AssignBoard,
                name: 'assign',
                meta: {
                    requiresAuth: true,
                },
                props: true,
            },
            {
                path: 'archive-tasks',
                name: '',
                component: ArchivedTasks,
                meta: {
                    requiresAuth: true,
                },
                props: true,
            },
            {
                path: 'clients',
                component: Clients,
                meta: {
                    requiresAuth: true,
                },
                props: true,
                children: [
                    {
                        path: '',
                        name: 'noClient',
                        component: NoClient,
                    },
                    {
                        path: ':id',
                        name: 'client',
                        component: Client,
                        props: true,
                        redirect: { name: 'clientGeneralInfo' },
                        children: [
                            {
                                path: 'general-information',
                                name: 'clientGeneralInfo',
                                component: ClientGeneralInfo,
                                props: true,
                            },
                            {
                                path: 'documents',
                                name: 'clientDocuments',
                                component: ClientDocuments,
                                props: true,
                            },
                            {
                                path: 'projects',
                                name: 'clientProjects',
                                component: ClientProjects,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'new',
                        name: 'newClient',
                        component: NewClient,
                    },
                ],
            },
            {
                path: 'users',
                name: 'users',
                component: UsersView,
                props: true,
                redirect: { name: 'users-register' },
                children: [
                    {
                        path: 'users-register',
                        name: 'users-register',
                        component: Users,
                        meta: {
                            requiresAuth: true,
                        },
                        children: [
                            {
                                path: '',
                                name: 'noUser',
                                component: NoUser,
                            },
                            {
                                path: ':id',
                                name: 'user',
                                component: User,
                                props: true,
                                redirect: { name: 'userGeneralInfo' },
                                children: [
                                    {
                                        path: 'general-information',
                                        name: 'userGeneralInfo',
                                        component: UserGeneralInfo,
                                        props: true,
                                    },
                                    {
                                        path: 'documents',
                                        name: 'userDocuments',
                                        component: UserDocuments,
                                        props: true,
                                    },
                                    {
                                        path: 'permissions',
                                        name: 'userPermissions',
                                        component: UserPermissions,
                                        props: true,
                                    },
                                    {
                                        path: 'logs',
                                        name: 'userlogs',
                                        component: UsersLogs,
                                        props: true,
                                    },
                                ],
                            },
                            {
                                path: 'new',
                                name: 'newUser',
                                component: NewUser,
                            },
                        ],
                    },
                    {
                        path: 'users-permissions',
                        name: 'users-permissions',
                        component: UsersPermissions,
                        meta: {
                            requiresAuth: true,
                        },
                    },
                    {
                        path: 'users-logs',
                        name: 'users-logs',
                        component: UsersLogs,
                        meta: {
                            requiresAuth: true,
                        },
                    },
                ],
            },
            {
                path: 'main-board',
                name: 'main board',
                component: MainBoard,
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: ':id',
                        name: 'quoteMainBoard',
                        component: Quote,
                        props: true,
                        children: [
                            {
                                path: 'general-information-quote-main-board',
                                name: 'quoteGIMainBoard',
                                component: QuoteGeneralInfo,
                                props: true,
                            },
                            {
                                path: 'commercial-terms-quote-main-board',
                                name: 'quoteCommercialTermsMainBoard',
                                beforeEnter: (to, from, next) => {
                                    const user = JSON.parse(
                                        localStorage.getItem('user')
                                    )
                                    const quoteDetails = user.permissions.find(
                                        x => x == 'quoteDetails'
                                    )
                                    if (quoteDetails) {
                                        next()
                                    } else {
                                        router.push({
                                            path: '/main-board',
                                        })
                                    }
                                },
                                component: QuoteCommercialTerms,
                                props: true,
                            },
                            {
                                path: 'costs-main-board',
                                component: QuoteCosts,
                                name: 'quoteCostsMainBoard',
                                beforeEnter: (to, from, next) => {
                                    const user = JSON.parse(
                                        localStorage.getItem('user')
                                    )
                                    const quoteDetails = user.permissions.find(
                                        x => x == 'quoteDetails'
                                    )
                                    if (quoteDetails) {
                                        next()
                                    } else {
                                        router.push({
                                            path: '/main-board',
                                        })
                                    }
                                },
                                props: true,
                            },
                            {
                                path: 'versions-main-board',
                                name: 'quoteChangelogMainBoard',
                                component: QuoteChangelog,
                                props: true,
                            },
                            {
                                path: 'tasks-main-board',
                                name: 'tasks-main-board',
                                component: AssignBoard,
                                meta: {
                                    requiresAuth: true,
                                },
                                props: true,
                            },
                            {
                                path: 'followUp-main-board',
                                name: 'quoteFollowUpMainBoard',
                                component: QuoteFollowUp,
                                props: true,
                            },
                            {
                                path: 'quote-logs-main-board',
                                name: 'quoteLogsMainBoard',
                                component: QuoteLogs,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'quotesByStatus',
                        name: 'quotesByStatus',
                        component: QuotesByStatus,
                        props: true,
                    },
                    {
                        path: 'quotesByAges',
                        name: 'quotesByAges',
                        component: QuotesByAges,
                        props: true,
                    },
                ],
            },
            {
                path: 'calculations',
                component: Calculations,
                name: 'calculations',
                meta: {
                    requiresAuth: true,
                },
                props: true,
                children: [
                    {
                        path: ':id',
                        name: 'calculation',
                        component: Calculation,
                        props: true,
                        children: [
                            {
                                path: 'calculation-params',
                                name: 'calculationParams',
                                component: CalculationParams,
                                props: true,
                            },

                            {
                                path: 'calculation-inputs',
                                name: 'calculationInputs',
                                component: CalculationInputs,
                                props: true,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'tools',
                name: 'tools',
                component: Tools,
                meta: {
                    requiresAuth: true,
                },
                props: true,
                redirect: { name: 'noTool' },
                children: [
                    {
                        path: '',
                        name: 'noTool',
                        component: NoTool,
                    },
                    {
                        path: 'images',
                        name: 'imagesTools',
                        component: ImagesTools,
                        props: true,
                        redirect: { name: 'commercialImages' },
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(to.params).length > 0) {
                                next()
                            } else {
                                next({ name: 'noTool' })
                            }
                        },
                        children: [
                            {
                                path: 'commercial',
                                name: 'commercialImages',
                                component: CommercialImages,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'documents',
                        name: 'documentsTools',
                        component: DocumentTools,
                        props: true,
                        redirect: { name: 'commercialDocuments' },
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(to.params).length > 0) {
                                next()
                            } else {
                                next({ name: 'noTool' })
                            }
                        },
                        children: [
                            {
                                path: 'technical',
                                name: 'technicalDocuments',
                                component: TechnicalDocuments,
                                props: true,
                            },
                            {
                                path: 'commercial',
                                name: 'commercialDocuments',
                                component: CommercialDocuments,
                                props: true,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'settings',
                name: 'settings',
                component: Settings,
                meta: {
                    requiresAuth: true,
                },
                redirect: { name: 'noItem' },
                props: true,
                children: [
                    {
                        path: '',
                        name: 'noItem',
                        component: NoItem,
                    },
                    {
                        path: 'quote',
                        name: 'quoteSettings',
                        component: QuoteSettings,
                        props: true,
                        redirect: { name: 'settingsCosts' },
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(to.params).length > 0) {
                                next()
                            } else {
                                next({ name: 'noItem' })
                            }
                        },
                        children: [
                            {
                                path: 'general-information',
                                name: 'settingsGeneralQuote',
                                component: SettingsGeneralQuote,
                                props: true,
                            },
                            {
                                path: 'commercial-terms',
                                name: 'settingsCommercial',
                                component: SettingsCommercial,
                                props: true,
                            },
                            {
                                path: 'quotes-ranges',
                                name: 'settingsQuotesRanges',
                                component: QuoteRangesSettings,
                                props: true,
                            },
                            {
                                path: 'costs',
                                name: 'settingsCosts',
                                component: SettingsCosts,
                                props: true,
                            },
                            {
                                path: 'pdfImage',
                                name: 'settingsImage',
                                component: SettingsImage,
                                props: true,
                            },
                            {
                                path: 'units',
                                name: 'settingsUnits',
                                component: SettingsUnits,
                                props: true,
                            },
                            {
                                path: 'emailBodySettings',
                                name: 'emailBodySettings',
                                component: () =>
                                    import(
                                        '../views/Settings/EmailBodySettings.vue'
                                    ),
                                props: true,
                            },
                            {
                                path: 'settingsReasons',
                                name: 'settingsReasons',
                                component: () =>
                                    import(
                                        '../views/Settings/SettingsReasons.vue'
                                    ),
                                props: true,
                            },
                            {
                                path: 'settingsInform',
                                name: 'settingsInform',
                                component: () =>
                                    import(
                                        '../views/Settings/SettingsInform.vue'
                                    ),
                                props: true,
                            },
                            {
                                path: 'settingsMilestones',
                                name: 'settingsMilestones',
                                component: Milestones,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'projects',
                        name: 'projectsSettings',
                        component: ProjectSettings,
                        props: true,
                        redirect: {
                            name: 'project-reports',
                        },
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(to.params).length > 0) {
                                next()
                            } else {
                                next({ name: 'noItem' })
                            }
                        },
                        children: [
                            {
                                path: 'project-reports',
                                name: 'project-reports',
                                component: ProjectReports,
                                props: true,
                                redirect: {
                                    name: 'forecast-reports',
                                },
                                children: [
                                    {
                                        path: 'forecast-reports',
                                        name: 'forecast-reports',
                                        component: ForecastReports,
                                        props: true,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'exchange',
                        name: 'exchangeSettings',
                        component: ExchangeSettings,
                        props: true,
                        redirect: { name: 'settingsCurrencies' },
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(to.params).length > 0) {
                                next()
                            } else {
                                next({ name: 'noItem' })
                            }
                        },
                        children: [
                            {
                                path: 'currencies',
                                name: 'settingsCurrencies',
                                component: SettingsCurrencies,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'company',
                        name: 'companySettings',
                        component: CompanySettings,
                        props: true,
                        redirect: { name: 'companyGeneralInfo' },
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(to.params).length > 0) {
                                next()
                            } else {
                                next({ name: 'noItem' })
                            }
                        },
                        children: [
                            {
                                path: 'general-information',
                                name: 'companyGeneralInfo',
                                component: CompanyGeneralInfo,
                                props: true,
                            },
                            {
                                path: 'companyTags',
                                name: 'companyTags',
                                component: CompanyTags,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'roles',
                        name: 'rolesSettings',
                        component: RolesSettings,
                        props: true,
                        redirect: { name: 'rolesPermissions' },
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(to.params).length > 0) {
                                next()
                            } else {
                                next({ name: 'noItem' })
                            }
                        },
                        children: [
                            {
                                path: 'roles-permissions',
                                name: 'rolesPermissions',
                                component: RolesPermissions,
                                props: true,
                            },
                            {
                                path: 'roles-permissions-details',
                                name: 'rolesPermissionsDetails',
                                component: RolesPermissionsDetails,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'calculation-settings',
                        name: 'calculationSettings',
                        component: CalculationSettings,
                        props: true,
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(to.params).length > 0) {
                                next()
                            } else {
                                next({ name: 'noItem' })
                            }
                        },
                        children: [
                            {
                                path: 'calculation-params-settings',
                                name: 'CalculationParamsSettings',
                                component: CalculationParams,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'workOrder',
                        name: 'workOrderSettings',
                        component: WorkOrderSettings,
                        props: true,
                        redirect: { name: 'products' },
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(to.params).length > 0) {
                                next()
                            } else {
                                next({ name: 'noItem' })
                            }
                        },
                        children: [
                            {
                                path: 'products',
                                name: 'products',
                                component: Products,
                                props: true,
                            },
                            {
                                path: 'processes',
                                name: 'processes',
                                component: Processes,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'quality',
                        name: 'qualitySettings',
                        component: QualitySettings,
                        props: true,
                        redirect: { name: 'ncCodes' },
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(to.params).length > 0) {
                                next()
                            } else {
                                next({ name: 'noItem' })
                            }
                        },
                        children: [
                            {
                                path: 'ncCodes',
                                name: 'ncCodes',
                                component: NCcodes,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'operators',
                        name: 'operators',
                        component: Operators,
                        props: true,
                    },
                    {
                        path: 'finish-and-uc-code',
                        name: 'finish-and-uc-code',
                        component: FinishAndUCCode,
                        props: true,
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(to.params).length > 0) {
                                next()
                            } else {
                                next({ name: 'noItem' })
                            }
                        },
                    },
                    {
                        path: 'machines',
                        name: 'machinesSettings',
                        component: Machines,
                        props: true,
                    },
                    {
                        path: 'specialMaterials',
                        name: 'specialMaterials',
                        component: SpecialMaterials,
                        props: true,
                    },
                    {
                        path: 'invoicing',
                        name: 'invoicingSettings',
                        component: InvoiceSettings,
                        props: true,
                        redirect: { name: 'invoiceConcepts' },
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(to.params).length > 0) {
                                next()
                            } else {
                                next({ name: 'noItem' })
                            }
                        },
                        children: [
                            {
                                path: 'invoice-concepts',
                                name: 'invoiceConcepts',
                                component: InvoiceConcepts,
                                props: true,
                            },
                            {
                                path: 'invoice-categories',
                                name: 'invoiceCategories',
                                component: InvoiceCategories,
                                props: true,
                            },
                            {
                                path: 'invoice-types',
                                name: 'invoiceTypes',
                                component: InvoiceTypes,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'notification-settings',
                        name: 'notification-settings',
                        component: NotificationSettings,
                        props: true,
                        redirect: {
                            name: 'lead-notifications',
                        },
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(to.params).length > 0) {
                                next()
                            } else {
                                next({ name: 'noItem' })
                            }
                        },
                        children: [
                            {
                                path: 'lead-notifications',
                                name: 'lead-notifications',
                                component: LeadNotifications,
                                props: true,
                                redirect: {
                                    name: 'lead-notifications-reject',
                                },
                                children: [
                                    {
                                        path: 'lead-notifications-reject',
                                        name: 'lead-notifications-reject',
                                        component: LeadTemplate,
                                        props: true,
                                    },
                                    {
                                        path: 'lead-notifications-bidding',
                                        name: 'lead-notifications-bidding',
                                        component: LeadTemplate,
                                        props: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'history',
                name: 'history',
                component: History,
                meta: {
                    requiresAuth: true,
                },
                redirect: { name: 'noHistory' },
                children: [
                    {
                        path: 'noHistory',
                        name: 'noHistory',
                        component: NoHistory,
                    },
                    {
                        path: ':id',
                        name: 'quoteHistory',
                        component: QuoteHistory,
                        props: true,
                        children: [
                            {
                                path: 'general-information',
                                name: 'historyGeneralInfo',
                                component: QuoteGeneralInfo,
                                props: true,
                            },
                            {
                                path: 'commercial-terms',
                                name: 'historyCommercialTerms',
                                component: QuoteCommercialTerms,
                                props: true,
                            },
                            {
                                path: 'costs',
                                component: QuoteCosts,
                                name: 'historyCosts',
                                props: true,
                            },
                            {
                                path: 'versions',
                                name: 'historyVersions',
                                component: QuoteChangelog,
                                props: true,
                            },
                            {
                                path: 'tasks-by-quote',
                                name: 'tasks-by-quote',
                                component: AssignBoard,
                                meta: {
                                    requiresAuth: true,
                                },
                                props: true,
                            },
                            {
                                path: 'followUp',
                                name: 'historyFollowUp',
                                component: QuoteFollowUp,
                                props: true,
                            },
                            {
                                path: 'quoteLogs',
                                name: 'historyQuoteLogs',
                                component: QuoteLogs,
                                props: true,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'statistics',
                name: 'statistics',
                meta: {
                    requiresAuth: true,
                },
                component: Statistics,
                props: true,
                children: [
                    {
                        path: ':id',
                        name: 'quote',
                        component: Quote,
                        props: true,
                        children: [
                            {
                                path: 'general-information',
                                name: 'quoteGeneralInfo',
                                component: QuoteGeneralInfo,
                                props: true,
                            },
                            {
                                path: 'commercial-terms',
                                name: 'quoteCommercialTerms',
                                beforeEnter: (to, from, next) => {
                                    const user = JSON.parse(
                                        localStorage.getItem('user')
                                    )
                                    const quoteDetails = user.permissions.find(
                                        x => x == 'quoteDetails'
                                    )
                                    if (quoteDetails) {
                                        next()
                                    } else {
                                        router.push({
                                            path: '/statistics',
                                        })
                                    }
                                },
                                component: QuoteCommercialTerms,
                                props: true,
                            },
                            {
                                path: 'costs',
                                component: QuoteCosts,
                                name: 'quoteCosts',
                                beforeEnter: (to, from, next) => {
                                    const user = JSON.parse(
                                        localStorage.getItem('user')
                                    )
                                    const quoteDetails = user.permissions.find(
                                        x => x == 'quoteDetails'
                                    )
                                    if (quoteDetails) {
                                        next()
                                    } else {
                                        router.push({
                                            path: '/statistics',
                                        })
                                    }
                                },
                                props: true,
                            },
                            {
                                path: 'versions',
                                name: 'quoteChangelog',
                                component: QuoteChangelog,
                                props: true,
                                beforeEnter: (to, from, next) => {
                                    const user = JSON.parse(
                                        localStorage.getItem('user')
                                    )
                                    const quoteDetails = user.permissions.find(
                                        x => x == 'quoteDetails'
                                    )
                                    if (quoteDetails) {
                                        next()
                                    } else {
                                        router.push({
                                            path: '/statistics',
                                        })
                                    }
                                },
                            },
                            {
                                path: 'tasks-by-quote',
                                name: 'tasks-statistics',
                                component: AssignBoard,
                                meta: {
                                    requiresAuth: true,
                                },
                                props: true,
                                beforeEnter: (to, from, next) => {
                                    const user = JSON.parse(
                                        localStorage.getItem('user')
                                    )
                                    const quoteDetails = user.permissions.find(
                                        x => x == 'quoteDetails'
                                    )
                                    if (quoteDetails) {
                                        next()
                                    } else {
                                        router.push({
                                            path: '/statistics',
                                        })
                                    }
                                },
                            },
                            {
                                path: 'followUp',
                                name: 'quoteFollowUp',
                                component: QuoteFollowUp,
                                props: true,
                                beforeEnter: (to, from, next) => {
                                    const user = JSON.parse(
                                        localStorage.getItem('user')
                                    )
                                    const quoteDetails = user.permissions.find(
                                        x => x == 'quoteDetails'
                                    )
                                    if (quoteDetails) {
                                        next()
                                    } else {
                                        router.push({
                                            path: '/statistics',
                                        })
                                    }
                                },
                            },
                            {
                                path: 'quoteLogs',
                                name: 'quoteLogs',
                                component: QuoteLogs,
                                props: true,
                                beforeEnter: (to, from, next) => {
                                    const user = JSON.parse(
                                        localStorage.getItem('user')
                                    )
                                    const quoteDetails = user.permissions.find(
                                        x => x == 'quoteDetails'
                                    )
                                    if (quoteDetails) {
                                        next()
                                    } else {
                                        router.push({
                                            path: '/statistics',
                                        })
                                    }
                                },
                            },
                        ],
                    },
                    {
                        path: 'quotesfromStatistics',
                        name: 'quotesfromStatistics',
                        component: QuotesByStatus,
                        props: true,
                    },
                ],
            },
            {
                path: 'tab-projects',
                name: 'tab-projects',
                meta: {
                    requiresAuth: true,
                },
                redirect: { name: 'projects' },
                component: ProjectTabs,
                props: true,
                children: [
                    {
                        path: 'projects',
                        name: 'projects',
                        meta: {
                            requiresAuth: true,
                        },
                        children: [
                            {
                                path: ':id',
                                name: 'project',
                                component: Project,
                                props: true,
                                redirect: { name: 'project-details' },
                                children: [
                                    {
                                        path: 'project-details',
                                        name: 'project-details',
                                        component: ProjectDetails,
                                        props: true,
                                    },
                                    {
                                        path: 'project-quotes',
                                        name: 'project-quotes',
                                        component: ProjectQuotes,
                                        props: true,
                                    },
                                    {
                                        path: 'project-milestones',
                                        name: 'project-milestones',
                                        component: QuoteMilestones,
                                        props: true,
                                    },
                                    {
                                        path: 'project-tasks',
                                        name: 'project-tasks',
                                        component: AssignBoard,
                                        props: true,
                                    },
                                    {
                                        path: 'project-quote-follow-up',
                                        name: 'project-quote-follow-up',
                                        component: QuoteFollowUp,
                                        props: true,
                                    },
                                    {
                                        path: 'project-purchases',
                                        name: 'project-purchases',
                                        component: PurchasingRequest,
                                        props: true,
                                    },
                                    {
                                        path: 'project-reserves',
                                        name: 'project-reserves',
                                        component: ProjectReserves,
                                        props: true,
                                        redirect: {
                                            name: 'project-reserved-items',
                                        },
                                        children: [
                                            {
                                                path: 'project-stock-items',
                                                name: 'project-stock-items',
                                                props: true,
                                                component: Stock,
                                            },
                                            {
                                                path: 'project-reserved-items',
                                                name: 'project-reserved-items',
                                                props: true,
                                                component: ProjectRequested,
                                            },
                                            {
                                                path: 'project-requested-items',
                                                name: 'project-requested-items',
                                                props: true,
                                                component: Reserves,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'project-part-numbers',
                                        name: 'project-part-numbers',
                                        component: PartNumbers,
                                        props: true,
                                    },
                                    {
                                        path: 'project-invoices',
                                        name: 'project-invoices',
                                        component: ProjectInvoices,
                                        props: true,
                                    },
                                    {
                                        path: 'b2b-forecast',
                                        name: 'b2b-forecast',
                                        component: B2BForecast,
                                        props: true,
                                    },
                                    {
                                        path: 'project-logs',
                                        name: 'project-logs',
                                        component: ProjectLogs,
                                        props: true,
                                    },
                                    {
                                        path: 'project-work-orders',
                                        name: 'project-work-orders',
                                        component: MainWorkOrders,
                                        props: true,
                                    },
                                    {
                                        path: 'project-wo-progress',
                                        name: 'project-wo-progress',
                                        component: Progress,
                                        props: true,
                                    },
                                    {
                                        path: 'project-bill-of-materials',
                                        name: 'project-bill-of-materials',
                                        component: ApprovalBOM,
                                        props: true,
                                    },
                                    {
                                        path: 'project-packing-lists',
                                        name: 'project-packing-lists',
                                        component: ProjectPackingLists,
                                        props: true,
                                    },
                                    {
                                        path: 'project-timesheets',
                                        name: 'project-timesheets',
                                        component: ProjectTimesheets,
                                        props: true,
                                        redirect: {
                                            name:
                                                'project-timesheets-user-reports',
                                        },
                                        children: [
                                            {
                                                path:
                                                    'project-timesheets-user-reports',
                                                name:
                                                    'project-timesheets-user-reports',
                                                props: true,
                                                component: TimesheetReports,
                                            },
                                            {
                                                path:
                                                    'project-timesheets-daily-reports',
                                                name:
                                                    'project-timesheets-daily-reports',
                                                props: true,
                                                component: DailyReports,
                                            },
                                            {
                                                path:
                                                    'project-timesheets-weekly-reports',
                                                name:
                                                    'project-timesheets-weekly-reports',
                                                props: true,
                                                component: WeeklyReports,
                                            },
                                            {
                                                path:
                                                    'project-weekly-timekeeping-reports',
                                                name:
                                                    'project-weekly-timekeeping-reports',
                                                props: true,
                                                component: WeeklyTimeKeepingReports,
                                            },
                                            {
                                                path:
                                                    'project-timesheets-settings',
                                                name:
                                                    'project-timesheets-settings',
                                                props: true,
                                                component: TimesheetSettings,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                        component: Projects,
                    },
                    {
                        path: 'projects-generic',
                        name: 'projects-generic',
                        meta: {
                            requiresAuth: true,
                        },
                        children: [
                            {
                                path: ':id',
                                name: 'project-generic',
                                component: Project,
                                props: true,
                                redirect: { name: 'project-details-generic' },
                                children: [
                                    {
                                        path: 'project-details-generic',
                                        name: 'project-details-generic',
                                        component: ProjectDetails,
                                        props: true,
                                    },
                                    {
                                        path: 'project-quotes-generic',
                                        name: 'project-quotes-generic',
                                        component: ProjectQuotes,
                                        props: true,
                                    },
                                    {
                                        path: 'project-milestones-generic',
                                        name: 'project-milestones-generic',
                                        component: QuoteMilestones,
                                        props: true,
                                    },
                                    {
                                        path: 'project-tasks-generic',
                                        name: 'project-tasks-generic',
                                        component: AssignBoard,
                                        props: true,
                                    },
                                    {
                                        path: 'project-quote-follow-up-generic',
                                        name: 'project-quote-follow-up-generic',
                                        component: QuoteFollowUp,
                                        props: true,
                                    },
                                    {
                                        path: 'project-purchases-generic',
                                        name: 'project-purchases-generic',
                                        component: PurchasingRequest,
                                        props: true,
                                    },
                                    {
                                        path: 'project-reserves-generic',
                                        name: 'project-reserves-generic',
                                        component: ProjectReserves,
                                        props: true,
                                        redirect: {
                                            name:
                                                'project-reserved-items-generic',
                                        },
                                        children: [
                                            {
                                                path:
                                                    'project-stock-items-generic',
                                                name:
                                                    'project-stock-items-generic',
                                                props: true,
                                                component: Stock,
                                            },
                                            {
                                                path:
                                                    'project-reserved-items-generic',
                                                name:
                                                    'project-reserved-items-generic',
                                                props: true,
                                                component: ProjectRequested,
                                            },
                                            {
                                                path:
                                                    'project-requested-items-generic',
                                                name:
                                                    'project-requested-items-generic',
                                                props: true,
                                                component: Reserves,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'project-part-numbers-generic',
                                        name: 'project-part-numbers-generic',
                                        component: PartNumbers,
                                        props: true,
                                    },
                                    {
                                        path: 'project-invoices-generic',
                                        name: 'project-invoices-generic',
                                        component: ProjectInvoices,
                                        props: true,
                                    },
                                    {
                                        path: 'b2b-forecast-generic',
                                        name: 'b2b-forecast-generic',
                                        component: B2BForecast,
                                        props: true,
                                    },
                                    {
                                        path: 'project-logs-generic',
                                        name: 'project-logs-generic',
                                        component: ProjectLogs,
                                        props: true,
                                    },
                                    {
                                        path: 'project-work-orders-generic',
                                        name: 'project-work-orders-generic',
                                        component: MainWorkOrders,
                                        props: true,
                                    },
                                    {
                                        path: 'project-wo-progress-generic',
                                        name: 'project-wo-progress-generic',
                                        component: Progress,
                                        props: true,
                                    },
                                    {
                                        path:
                                            'project-bill-of-materials-generic',
                                        name:
                                            'project-bill-of-materials-generic',
                                        component: ApprovalBOM,
                                        props: true,
                                    },
                                    {
                                        path: 'project-packing-lists-generic',
                                        name: 'project-packing-lists-generic',
                                        component: ProjectPackingLists,
                                        props: true,
                                    },
                                    {
                                        path: 'project-timesheets-generic',
                                        name: 'project-timesheets-generic',
                                        component: ProjectTimesheets,
                                        props: true,
                                        redirect: {
                                            name:
                                                'project-timesheets-user-reports-generic',
                                        },
                                        children: [
                                            {
                                                path:
                                                    'project-timesheets-user-reports-generic',
                                                name:
                                                    'project-timesheets-user-reports-generic',
                                                props: true,
                                                component: TimesheetReports,
                                            },
                                            {
                                                path:
                                                    'project-timesheets-daily-reports-generic',
                                                name:
                                                    'project-timesheets-daily-reports-generic',
                                                props: true,
                                                component: DailyReports,
                                            },
                                            {
                                                path:
                                                    'project-timesheets-weekly-reports-generic',
                                                name:
                                                    'project-timesheets-weekly-reports-generic',
                                                props: true,
                                                component: WeeklyReports,
                                            },
                                            {
                                                path:
                                                    'project-weekly-timekeeping-reports-generic',
                                                name:
                                                    'project-weekly-timekeeping-reports-generic',
                                                props: true,
                                                component: WeeklyTimeKeepingReports,
                                            },
                                            {
                                                path:
                                                    'project-timesheets-settings-generic',
                                                name:
                                                    'project-timesheets-settings-generic',
                                                props: true,
                                                component: TimesheetSettings,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                        component: GenericProjects,
                    },
                    {
                        path: 'closing-projects',
                        name: 'closing-projects',
                        meta: {
                            requiresAuth: true,
                        },
                        component: ClosingProjects,
                        props: true,
                        beforeEnter: (to, from, next) => {
                            const user = JSON.parse(
                                localStorage.getItem('user')
                            )
                            if (
                                user.permissions.find(
                                    x => x == 'archiveProject'
                                ) ||
                                user.permissions.find(
                                    x => x == 'comexEvidence'
                                ) ||
                                user.permissions.find(
                                    x => x == 'portfolioEvidence'
                                ) ||
                                user.permissions.find(x => x == 'sapEvidence')
                            ) {
                                next()
                            } else {
                                router.push({
                                    path: '/',
                                })
                            }
                        },
                    },
                    {
                        path: 'nomina-projects',
                        name: 'nomina-projects',
                        meta: {
                            requiresAuth: true,
                        },
                        component: Nomina,
                    },
                ],
            },
            {
                path: 'forecast',
                name: 'forecast',
                meta: {
                    requiresAuth: true,
                },
                component: Forecast,
                props: true,
                children: [],
            },
            {
                path: 'archive-projects',
                name: 'archive-projects',
                meta: {
                    requiresAuth: true,
                },
                component: ArchiveProjects,
                props: true,
                children: [
                    {
                        path: ':id',
                        name: 'archive-project',
                        component: Project,
                        props: true,
                        redirect: { name: 'archive-project-details' },
                        children: [
                            {
                                path: 'archive-project-details',
                                name: 'archive-project-details',
                                component: ProjectDetails,
                                props: true,
                            },
                            {
                                path: 'archive-project-quotes',
                                name: 'archive-project-quotes',
                                component: ProjectQuotes,
                                props: true,
                            },
                            {
                                path: 'archive-project-milestones',
                                name: 'archive-project-milestones',
                                component: QuoteMilestones,
                                props: true,
                            },
                            {
                                path: 'archive-project-tasks',
                                name: 'archive-project-tasks',
                                component: AssignBoard,
                                props: true,
                            },
                            {
                                path: 'archive-project-quote-follow-up',
                                name: 'archive-project-quote-follow-up',
                                component: QuoteFollowUp,
                                props: true,
                            },
                            {
                                path: 'archive-project-purchases',
                                name: 'archive-project-purchases',
                                component: PurchasingRequest,
                                props: true,
                            },
                            {
                                path: 'archive-project-reserves',
                                name: 'archive-project-reserves',
                                component: ProjectReserves,
                                props: true,
                                redirect: {
                                    name: 'archive-project-reserved-items',
                                },
                                children: [
                                    {
                                        path: 'archive-project-stock-items',
                                        name: 'archive-project-stock-items',
                                        props: true,
                                        component: Stock,
                                    },
                                    {
                                        path: 'archive-project-reserved-items',
                                        name: 'archive-project-reserved-items',
                                        props: true,
                                        component: ProjectRequested,
                                    },
                                    {
                                        path: 'archive-project-requested-items',
                                        name: 'archive-project-requested-items',
                                        props: true,
                                        component: Reserves,
                                    },
                                ],
                            },
                            {
                                path: 'archive-project-part-numbers',
                                name: 'archive-project-part-numbers',
                                component: PartNumbers,
                                props: true,
                            },
                            {
                                path: 'archive-project-invoices',
                                name: 'archive-project-invoices',
                                component: ProjectInvoices,
                                props: true,
                            },
                            {
                                path: 'archive-b2b-forecast',
                                name: 'archive-b2b-forecast',
                                component: B2BForecast,
                                props: true,
                            },
                            {
                                path: 'archive-project-logs',
                                name: 'archive-project-logs',
                                component: ProjectLogs,
                                props: true,
                            },
                            {
                                path: 'archive-project-work-orders',
                                name: 'archive-project-work-orders',
                                component: MainWorkOrders,
                                props: true,
                            },
                            {
                                path: 'archive-project-wo-progress',
                                name: 'archive-project-wo-progress',
                                component: Progress,
                                props: true,
                            },
                            {
                                path: 'archive-project-bill-of-materials',
                                name: 'archive-project-bill-of-materials',
                                component: ApprovalBOM,
                                props: true,
                            },
                            {
                                path: 'archive-project-packing-lists',
                                name: 'archive-project-packing-lists',
                                component: ProjectPackingLists,
                                props: true,
                            },
                            {
                                path: 'archive-project-timesheets',
                                name: 'archive-project-timesheets',
                                component: ProjectTimesheets,
                                props: true,
                                redirect: {
                                    name:
                                        'archive-project-timesheets-user-reports',
                                },
                                children: [
                                    {
                                        path:
                                            'archive-project-timesheets-user-reports',
                                        name:
                                            'archive-project-timesheets-user-reports',
                                        props: true,
                                        component: TimesheetReports,
                                    },
                                    {
                                        path:
                                            'archive-project-timesheets-daily-reports',
                                        name:
                                            'archive-project-timesheets-daily-reports',
                                        props: true,
                                        component: DailyReports,
                                    },
                                    {
                                        path:
                                            'archive-project-timesheets-weekly-reports',
                                        name:
                                            'archive-project-timesheets-weekly-reports',
                                        props: true,
                                        component: WeeklyReports,
                                    },
                                    {
                                        path:
                                            'archive-project-weekly-timekeeping-reports',
                                        name:
                                            'archive-project-weekly-timekeeping-reports',
                                        props: true,
                                        component: WeeklyTimeKeepingReports,
                                    },
                                    {
                                        path:
                                            'archive-project-timesheets-settings',
                                        name:
                                            'archive-project-timesheets-settings',
                                        props: true,
                                        component: TimesheetSettings,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'stock-room',
                name: 'stock-room',
                meta: {
                    requiresAuth: true,
                },
                component: StockRoom,
                props: true,
                redirect: { name: 'stock' },
                children: [
                    {
                        path: 'stock',
                        name: 'stock',
                        component: Stock,
                        props: true,
                    },
                    {
                        path: 'request',
                        name: 'request',
                        component: Request,
                        props: true,
                    },
                    {
                        path: 'reserves',
                        name: 'reserves',
                        component: Reserves,
                        props: true,
                    },
                    {
                        path: 'requested-boms',
                        name: 'requested-boms',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: PartialDeliveryRequests,
                    },
                    {
                        path: 'boms-delivered',
                        name: 'boms-delivered',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: BOMsDelivered,
                    },
                    {
                        path: 'closed-boms',
                        name: 'closed-boms',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: ApprovalBOM,
                    },
                ],
            },
            {
                path: 'purchasing',
                name: 'purchasing',
                meta: {
                    requiresAuth: true,
                },
                component: Purchasing,
                props: true,
                redirect: { name: 'purchasing-requests' },
                children: [
                    {
                        path: 'purchasing-requests',
                        name: 'purchasing-requests',
                        meta: {
                            requiresAuth: true,
                        },
                        component: PurchasingRequest,
                    },
                    {
                        path: 'purchasing-pricing',
                        name: 'purchasing-pricing',
                        meta: {
                            requiresAuth: true,
                        },
                        component: PurchasingPricing,
                    },
                    {
                        path: 'purchasing-pre-approval',
                        name: 'purchasing-pre-approval',
                        meta: {
                            requiresAuth: true,
                        },
                        component: PurchasingPreApproval,
                    },
                    {
                        path: 'purchasing-approval',
                        name: 'purchasing-approval',
                        meta: {
                            requiresAuth: true,
                        },
                        component: PurchasingApproval,
                    },
                    {
                        path: 'purchasing-process',
                        name: 'purchasing-process',
                        meta: {
                            requiresAuth: true,
                        },
                        component: PurchasingProcess,
                    },
                    {
                        path: 'purchasing-in-transit',
                        name: 'purchasing-in-transit',
                        meta: {
                            requiresAuth: true,
                        },
                        component: PurchasingInTransit,
                    },
                    {
                        path: 'purchasing-received',
                        name: 'purchasing-received',
                        meta: {
                            requiresAuth: true,
                        },
                        component: PurchasingReceived,
                    },
                    {
                        path: 'purchasing-closed',
                        name: 'purchasing-closed',
                        meta: {
                            requiresAuth: true,
                        },
                        component: PurchasingClosed,
                    },
                    {
                        path: 'purchasing-rejected-log',
                        name: 'purchasing-rejected-log',
                        meta: {
                            requiresAuth: true,
                        },
                        component: PurchasingRejectedLog,
                    },
                    {
                        path: 'purchasing-assign',
                        name: 'purchasing-assign',
                        meta: {
                            requiresAuth: true,
                        },
                        component: PurchasingAssign,
                    },
                    {
                        path: 'purchasing-status',
                        name: 'purchasing-status',
                        meta: {
                            requiresAuth: true,
                        },
                        component: PurchasingStatus,
                    },
                    {
                        path: 'purchases-statistics',
                        name: 'purchases-statistics',
                        meta: {
                            requiresAuth: true,
                        },
                        component: PurchasesStatistics,
                    },
                    {
                        path: 'purchases-indicators',
                        name: 'purchases-indicators',
                        meta: {
                            requiresAuth: true,
                        },
                        component: PurchasesIndicators,
                    },
                ],
            },
            {
                path: 'comex',
                name: 'comex',
                meta: {
                    requiresAuth: true,
                },
                component: Comex,
                props: true,
                redirect: { name: 'from-comex' },
                children: [
                    {
                        path: 'from-comex',
                        name: 'from-comex',
                        meta: {
                            requiresAuth: true,
                        },
                        component: ComexMainBoard,
                    },
                    {
                        path: 'comex-completed',
                        name: 'comex-completed',
                        meta: {
                            requiresAuth: true,
                        },
                        component: ComexFinished,
                    },
                ],
            },
            {
                path: 'suppliers',
                name: 'suppliers',
                meta: {
                    requiresAuth: true,
                },
                component: Suppliers,
                redirect: { name: 'no-supplier' },
                children: [
                    {
                        path: 'no-supplier',
                        name: 'no-supplier',
                        component: NoSupplier,
                    },
                    {
                        path: ':id',
                        name: 'supplier',
                        component: Supplier,
                        props: true,
                        redirect: { name: 'supplier-general-info' },
                        children: [
                            {
                                path: 'supplier-general-info',
                                name: 'supplier-general-info',
                                component: SupplierGeneralInfo,
                                props: true,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'leads',
                name: 'leads',
                meta: {
                    requiresAuth: true,
                },
                component: Leading,
                props: true,
                redirect: { name: 'leads-inprogress' },
                children: [
                    {
                        path: 'leads-inprogress',
                        name: 'leads-inprogress',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: Leads,
                    },
                    {
                        path: 'leads-onhold',
                        name: 'leads-onhold',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: Leads,
                    },
                    {
                        path: 'leads-approved',
                        name: 'leads-approved',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: Leads,
                    },
                    {
                        path: 'leads-rejected',
                        name: 'leads-rejected',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: Leads,
                    },
                    {
                        path: 'leads-modifiedQuote',
                        name: 'leads-modifiedQuote',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: Leads,
                    },
                ],
            },
            {
                path: 'estimating-plan',
                name: 'estimating-plan',
                meta: {
                    requiresAuth: true,
                },
                component: Estimating,
                props: true,
            },
            {
                path: 'assign-engineer',
                name: 'assign-engineer',
                meta: {
                    requiresAuth: true,
                },
                component: CostAssing,
                props: true,
            },
            {
                path: 'engineering',
                name: 'engineering',
                meta: {
                    requiresAuth: true,
                },
                component: Engineering,
                props: true,
            },
            {
                path: 'engineering-history',
                name: 'engineering-history',
                meta: {
                    requiresAuth: true,
                },
                component: EngineeringHistory,
                props: true,
            },
            {
                path: 'my-scopes',
                name: 'my-scopes',
                component: MyScopes,
                meta: {
                    requiresAuth: true,
                },
                redirect: { name: 'noScopes' },
                children: [
                    {
                        path: 'noScopes',
                        name: 'noScopes',
                        component: NoScopes,
                    },
                    {
                        path: ':id',
                        name: 'scopeForm',
                        component: NewScopeForm,
                        props: true,
                    },
                ],
            },
            {
                path: 'work-orders',
                name: 'work-orders',
                meta: {
                    requiresAuth: true,
                },
                component: WorkOrders,
                redirect: { name: 'main-work-orders' },
                children: [
                    {
                        path: 'main-work-orders',
                        name: 'main-work-orders',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: MainWorkOrders,
                    },
                    {
                        path: 'approval-wo',
                        name: 'approval-wo',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: ApprovalWO,
                    },
                    {
                        path: 'approved-wo',
                        name: 'approved-wo',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: ApprovedWO,
                    },
                    {
                        path: 'finished',
                        name: 'finished',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: Finished,
                    },
                    {
                        path: 'qa-pending-wo',
                        name: 'qa-pending-wo',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: PendingQA,
                    },
                    {
                        path: 'qa-rejected-wo',
                        name: 'qa-rejected-wo',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: RejectedQA,
                    },
                    {
                        path: 'approval-bom',
                        name: 'approval-bom',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: ApprovalBOM,
                    },
                    {
                        path: 'approved-bom',
                        name: 'approved-bom',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: ApprovedBOM,
                    },
                    {
                        path: 'closed',
                        name: 'closed',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: Closed,
                    },
                    {
                        path: 'canceled',
                        name: 'canceled',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: Canceled,
                    },
                    {
                        path: 'wo-deliveries',
                        name: 'wo-deliveries',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: Deliveries,
                    },
                    {
                        path: 'wo-progress',
                        name: 'wo-progress',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: Progress,
                    },
                ],
            },
            {
                path: 'packing',
                name: 'packing',
                meta: {
                    requiresAuth: true,
                },
                component: Packing,
                redirect: { name: 'wo-ready-for-packing' },
                children: [
                    {
                        path: 'wo-ready-for-packing',
                        name: 'wo-ready-for-packing',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: ReadyForPackingWO,
                    },
                    {
                        path: 'main-packing',
                        name: 'main-packing',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: MainPacking,
                    },
                    {
                        path: 'pending-packing',
                        name: 'pending-packing',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: PendingPacking,
                    },
                    {
                        path: 'referred-packing',
                        name: 'referred-packing',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: ReferredPacking,
                    },
                    {
                        path: 'scheduled-packing',
                        name: 'scheduled-packing',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: ScheduledPacking,
                    },
                    {
                        path: 'shipped-packing',
                        name: 'shipped-packing',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: ShippedPacking,
                    },
                    {
                        path: 'completed-packing',
                        name: 'completed-packing',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: CompletedPacking,
                    },
                    {
                        path: 'packing-balance',
                        name: 'packing-balance',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: PackingBalance,
                    },
                ],
            },
            {
                path: 'invoicing',
                name: 'invoicing',
                meta: {
                    requiresAuth: true,
                },
                component: Invoicing,
                redirect: { name: 'invoices' },
                children: [
                    {
                        path: 'invoices',
                        name: 'invoices',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: Invoices,
                    },
                    {
                        path: 'international',
                        name: 'international',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: International,
                    },
                    {
                        path: 'generating',
                        name: 'generating',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: Generating,
                    },
                    {
                        path: 'invoicing-closed',
                        name: 'invoicing-closed',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: ClosedInvoices,
                    },
                ],
            },
            {
                path: '/operator',
                name: 'operator',
                component: OperatorView,
                meta: {
                    requiresAuth: true,
                },
                redirect: { name: 'wo-bom-search' },
                children: [
                    {
                        path: 'wo-bom-search',
                        name: 'wo-bom-search',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: OperatorSearch,
                    },
                    {
                        path: 'bom',
                        name: 'bom',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: PartialDeliveryRequests,
                    },
                    {
                        path: 'wo',
                        name: 'wo',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: OperatorWOs,
                    },
                    {
                        path: 'finished-wo',
                        name: 'finished-wo',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: OperatorsFinishedWOs,
                    },
                    {
                        path: 'closed-wo',
                        name: 'closed-wo',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: OperatorClosedWOs,
                    },
                    {
                        path: 'quality-pending',
                        name: 'quality-pending',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: PendingQA,
                    },
                    {
                        path: 'operator-wo-deliveries',
                        name: 'operator-wo-deliveries',
                        meta: {
                            requiresAuth: true,
                        },
                        props: true,
                        component: Deliveries,
                    },
                ],
            },
            {
                path: 'chats',
                name: 'chats',
                meta: {
                    requiresAuth: true,
                },
                component: Chats,
                children: [
                    {
                        path: '',
                        name: 'no-chat',
                        component: NoChat,
                    },
                    {
                        path: ':id',
                        name: 'chat',
                        props: true,
                        component: QuoteFollowUp,
                    },
                ],
            },
            {
                path: 'project-chats',
                name: 'project-chats',
                meta: {
                    requiresAuth: true,
                },
                component: ProjectChats,
                children: [
                    {
                        path: '',
                        name: 'project-no-chat',
                        component: NoChat,
                    },
                    {
                        path: ':id',
                        name: 'project-chat',
                        props: true,
                        component: QuoteFollowUp,
                    },
                ],
            },
            {
                path: 'poc',
                name: 'poc',
                props: true,
                beforeEnter: async (to, from, next) => {
                    const {
                        data: { user },
                    } = await API.getMyInfo()
                    const seePocSection = user.permissions.find(
                        x => x == 'seePocSection'
                    )
                    if (seePocSection) {
                        next()
                    } else {
                        router.push({
                            path: '/tasks',
                        })
                    }
                },
                component: POC,
            },
        ],
    },
    {
        path: '/launchscreen',
        name: 'launchscreen',
        props: true,
        component: LaunchScreen,
    },
    {
        path: '/temporaryOffline',
        name: 'temporaryOffline',
        component: TemporaryOffline,
    },
    {
        path: '/WO/:companyId',
        name: 'WO',
        component: WOSearch,
        props: true,
    },
    {
        path: '/BOM/:companyId',
        name: 'BOM',
        component: BOMSearch,
        props: true,
    },
    {
        path: '/operator-bom',
        name: 'ApprovedBOM',
        component: BOMSearch,
        props: true,
    },
    {
        path: '/public-work-order',
        name: 'PublicWorkOrder',
        component: PublicWorkOrder,
        props: true,
    },
    {
        path: '/create-report',
        name: 'create-report',
        component: CreateReport,
        meta: {
            requiresAuth: false,
        },
        props: true,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeResolve(async (to, from, next) => {
    // Simulate user signed in
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    //user signed in
    const user = auth().currentUser
    if (user) {
        let lastSignInTime = new Date(user.metadata.lastSignInTime)
        const currentDate = Date.now()
        if (currentDate - lastSignInTime >= 20 * 24 * 60 * 60 * 1000) {
            await auth().signOut()
        }
    }
    if (
        user &&
        !store.state.ready &&
        !store.state.userRef &&
        !user.isAnonymous &&
        to.name != 'timesheets' &&
        to.name != 'create-report' &&
        to.name != 'purchase quotes'
    ) {
        store.dispatch('loadReadyAction')
        next({
            name: 'launchscreen',
            params: { currentPath: to.path, uid: user.uid },
        })
    } else {
        if (
            ((user && store.state.userRef) ||
                (user && JSON.parse(localStorage.getItem('user')))) &&
            !user.isAnonymous
        ) {
            // User is signed in
            if (requiresAuth) {
                const user = store.state.userRef
                const localUser = JSON.parse(localStorage.getItem('user'))

                if (user.id !== localUser.id) {
                    await auth().signOut()
                    next({ name: 'sign in' })
                } else {
                    // Continue to protected route
                    if (
                        from.name == 'launchscreen' &&
                        to.path.includes(`main-board/${to.params.id}`)
                    ) {
                        next({ name: 'main board' })
                    } else {
                        next()
                    }
                }
            } else {
                next()
            }
        } else {
            if (to.path === '/') {
                // Sign in
                next({ name: 'sign in' })
            } else if (to.name === 'sign in' || to.name === 'reset password') {
                // Continue
                next()
            } else if (to.name === 'WO') {
                next()
            } else if (to.name === 'purchase quotes') {
                next()
            } else if (to.name === 'main-work-orders') {
                next({
                    name: 'PublicWorkOrder',
                    params: {
                        orderId: to.query.orderId,
                        code: to.query.code,
                        companyId: to.query.companyId,
                    },
                })
            } else if (to.name === 'BOM') {
                next()
            } else if (to.name === 'timesheets') {
                next()
            } else if (to.name === 'create-report') {
                next()
            } else {
                //sign in
                next({ name: 'sign in' })
            }
        }
    }
})
export default router
